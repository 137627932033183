<template>
  <v-app id="inspire">
    <Snackbar />
    <v-app-bar
      app
      color='primary'
      elevate-on-scroll
    >
      <v-app-bar-nav-icon><v-img :src="logobkpsdm" style="margin-left:65px !important" class="ml-10" contain max-height="40"></v-img></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="newTabLink('https://www.instagram.com/bkpsdm.bdg')"><v-icon>mdi-instagram</v-icon></v-btn>
      <v-btn icon dark @click="newTabLink('https://web.facebook.com/bkpsdmbdg')"><v-icon>mdi-facebook</v-icon></v-btn>
      <v-btn icon dark @click="newTabLink('https://twitter.com/bkppbdg')"><v-icon>mdi-twitter</v-icon></v-btn>

    </v-app-bar>

    <v-main>
      <v-container
        class="fill-height"
      >
        <v-toolbar flat color="grey lighten-3">
          <v-app-bar-nav-icon><v-img :src="logo" class="ml-10" contain width="96"></v-img></v-app-bar-nav-icon>
          <!-- <v-toolbar-title><v-img :src="logo" contain height="50" width="300"></v-img></v-toolbar-title> -->
          <v-spacer></v-spacer>
          <v-btn rounded @click="gotoLogin()" color="primary" dark><v-icon class="mr-2">mdi-login-variant</v-icon> masuk</v-btn>
        </v-toolbar>

        <!-- <v-row align="start" justify="center" style="padding-top:40px">
          <v-col cols="12">
            <v-carousel
              cycle
              height="400"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <template v-for="(slide,i) in slides">
                <v-carousel-item
                  :key="i"
                  @click="newTabLink(slide.to)"
                  v-if='slide.type=="quote"'
                >
                  <v-sheet
                    :color="colors[i]"
                    height="100%"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <div class="display-2 pa-5" style="text-align:center">{{ slide.content }}</div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item
                  v-else
                  @click="newTabLink(slide.to)"
                  :key="i"
                  :src="slide.content"
                  contain
                ></v-carousel-item>
              </template>
            </v-carousel>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="center" class="mt-15">
          <!-- <v-img src="@/assets/mangbagja500px.png" alt="" height="150" class="animate__animated animate__bounceIn" contain/> -->
          <v-col cols="12" class="d-flex flex-column align-center justify-center">
            <v-carousel
              cycle
              height="150"
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="false"
              hide-delimiters
            >
              <template v-for="(slide,i) in quotes">
                <v-carousel-item
                  :key="i"
                  @click="newTabLink(slide.to)"
                  v-if='slide.type=="quote"'
                >
                  <v-sheet
                    color="grey lighten-3"
                    height="100%"
                  >
                    <v-row
                      align="center"
                      justify="center"
                    >
                      <div class="display-2 pa-5" style="text-align:center">{{ slide.content }}</div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item
                  v-else
                  @click="newTabLink(slide.to)"
                  :key="i"
                  :src="slide.content"
                  contain
                ></v-carousel-item>
              </template>
            </v-carousel>
          </v-col>
        </v-row>

        <h1 class="text-h2">Berita ASN</h1>
        <v-row>
          <!-- untuk berita -->
          <v-col
            lg="3" md="6"
             v-for="(item, i) in displayNews"
             :key="i">
            <v-card
              class="mx-auto animate__animated animate__fadeIn elevation-3"
              outlined
              transition="fade-transition"
              @click="displayCurrent(item)"
            >
              <v-img v-if="item.link" class="white--text align-end"
                height="200px"
                :src="item.link[0]">
                <div style="background:rgba(0,0,0,0.5)">
                  <span class="text-h5 ma-3">{{item.judul}}</span>
                  <span class="overline">#{{item.id}}</span>
                </div>
              </v-img>
              <v-card-text style="max-height:200px;overflow:hidden;" class="text-body-1" v-else>
                <div>
                  <span class="text-h5">{{item.judul}}</span>
                  <span class="overline">#{{item.id}}</span>
                </div>
                <div v-html="item.isi"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-show="displayItem < recentNews.length">
          <v-col justify="center" align="center">
            <v-btn @click="updateNews()">lihat berita sebelumnya</v-btn>
          </v-col>
        </v-row>

        <!-- untuk logo aplikasi -->
        <h1 class="text-h2">Aplikasi Kota Bandung</h1>
        <v-row align="start" justify="center">
          <v-col v-for="(item,i) in aplikasiLain" :key="i" md="4" cols="4" lg="2">
            <v-card color=" blue lighten-4 elevation-1 pa-2" @click="newTabLink(item.link)">
              <v-img
              height="60"
              :src="item.logo"
              contain></v-img>
            </v-card>
          </v-col>
        </v-row>

        <!-- sosial media -->
        <v-row>
          <v-col cols="12" md="6" lg="9">
            <v-card>
              <iframe width="100%" height="400" src="https://www.youtube.com/embed/videoseries?list=PLdQ62Kr33gEeoz1GIk-pAbDHADw4YoLLj" frameborder="0" allowfullscreen></iframe>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-card height="400" width="100%" style="overflow:auto">
              <a class="twitter-timeline" href="https://twitter.com/bkpsdm_bdg" data-tweet-limit="15" data-width="100%" ></a>
            </v-card>
          </v-col>
        </v-row>
        <!-- detail dialog -->
        <v-dialog
          v-model="detailDialog"
          max-width="800"
          scrollable
        >
          <v-card v-if="currentDisplayed">
            <v-card-title class="headline">
              {{currentDisplayed.judul}}
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                icon
                fab
                @click="detailDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text style="max-height:calc( 100vh - 50px )">
              <div v-if="currentDisplayed.link">
                <v-img v-for="(link, i) in currentDisplayed.link" :src="link" :key="i"></v-img>
              </div>
              <div v-else>
                <div class="text-body-1" v-html="currentDisplayed.isi"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

      </v-container>
      <v-footer color="primary" padless>
        <v-row
          justify="center"
          no-gutters
        >
          <v-col
            class="primary text-center white--text"
            cols="12"
          >
            <!-- {{ new Date().getFullYear() }} © <strong>BKPSDM Bandung </strong> -->
            <small>Copyright © 2020 - {{ new Date().getFullYear() }} BKPSDM Kota Bandung | {{MbVersion}}<!--.{{version_date}}--> . {{MbBuild}}</small>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
  import Snackbar from '@/components/common/Snackbar'
  import 'animate.css'
  import router from '@/router'
  // import NewsService from '@/services/NewsService'
  import store from '@/store'
  import axios from 'axios'
  import _g from '../../global'
  export default {
    name: 'LayoutsDemosBaselineFlipped',
    components:{
      Snackbar
    },
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      displayItem:4,
      displayPerClick:4,
      detailDialog:false,
      currentDisplayed:{},
      logo:require('@/assets/logo9.png'),
      logobkpsdm:require('@/assets/bkpsdm.png'),
      colors: [
          'indigo',
          'yellow darken-2',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
      ],
      slides: [
        // {content:require('@/assets/Tree musketeers.jpg'), type:'img', to:'https://google.com'},
        {content:'“Kecantikan yang abadi terletak pada keelokan adab dan ketinggian ilmu seseorang. Bukan terletak pada wajah dan pakaiannya.” ― Hamka', type:'quote'},
        {content:'“Hanya ada satu negara yang pantas menjadi negaraku. Ia tumbuh dengan perbuatan dan perbuatan itu adalah perbuatanku.” ― Mohammad Hatta', type:'quote'},
        {content:'"Perjuanganku lebih mudah karena mengusir penjajah, perjuanganmu akan lebih sulit karena melawan bangsamu sendiri." - Soekarno', type:'quote'},
        {content:'“Jangan mudah tergelincir dalam saat-saat seperti ini, segala tipu muslihat dan provokasi-provokasi yang tampak atau tersembunyi dapat dilalui dengan selamat, kalau kita waspada dan bertindak sebagai patriot.” - Jendral Sudirman', type:'quote'},
        {content:'“Cita-cita persatuan Indonesia itu bukan omong kosong, tetapi benar-benar didukung oleh kekuatan-kekuatan yang timbul pada akar sejarah bangsa kita sendiri.” - Mohammad Yamin', type:'quote'},
      ],
      recentNews:[],
      aplikasiLain:[
        {logo:require('@/assets/logo_aplikasi_lain/ESAKIP.png'),link:'http://esakip.bandung.go.id'},
        {logo:require('@/assets/logo_aplikasi_lain/humas_color.png'),link:'https://humas.bandung.go.id'},
        {logo:require('@/assets/logo_aplikasi_lain/logo_square_color.png'),link:'http://data.bandung.go.id'},
        {logo:require('@/assets/logo_aplikasi_lain/JDIH.png'),link:'https://jdih.bandung.go.id'},
        {logo:require('@/assets/logo_aplikasi_lain/PortalBandung.png'),link:'https://portal.bandung.go.id'},
        {logo:require('@/assets/logo_aplikasi_lain/pusicov_landscape.png'),link:'https://covid19.bandung.go.id'},
      ],
      quotes:[
        {content: "“Datang bersama merupakan awal perjalanan, tetap bersama merupakan kemajuan. Bekerja sama dalam tim adalah kesuksesan.” ― Henry Ford", type:"quote"},
        {content: "“Struggle that you do today is the single way to build a better future.”", type:"quote"},
        {content: "“Orang hebat tidak dihasilkan dari kemudahan, kesenangan, dan kenyamanan. Mereka dibentuk melalui kesulitan, tantangan, dan air mata.” ― Dahlan Iskan", type:"quote"} ,
        {content: "“Develop a passion for learning, if you do you'll never cease to grow.”", type:"quote"},
        {content: "“Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.” ― Ibnu Sina", type:"quote"},
        {content: "“Find a group of people who challenge and inspire you, spend a lot of time with them, and it will change your life.” ― Amy Poehler", type:"quote"},
        {content: "“Keberhasilan bukanlah milik orang yang pintar. Keberhasilan adalah kepunyaan mereka yang senantiasa berusaha.” ― B.J. Habibie", type:"quote"},
        {content: "“Kadang kita terlalu sibuk memikirkan kesulitan-kesulitan sehingga kita tidak punya waktu untuk mensyukuri rahmat Tuhan.” ― Jenderal Sudirman", type:"quote"},
        {content: "“Kurang cerdas dapat diperbaiki dengan belajar. Kurang cakap dapat dihilangkan dengan pengalaman. Namun tidak jujur itu sulit diperbaiki.” ― Mohammad Hatta", type:"quote"},
        {content: "“Jangan takut jatuh, kerana yang tidak pernah memanjatlah yang tidak pernah jatuh. Yang takut gagal, kerana yang tidak pernah gagal hanyalah orang-orang yang tidak pernah melangkah.” ― Buya Hamka", type:"quote"},
        {content: '“Kecantikan yang abadi terletak pada keelokan adab dan ketinggian ilmu seseorang. Bukan terletak pada wajah dan pakaiannya.” ― Buya Hamka', type:'quote'},
        {content: '"Perjuanganku lebih mudah karena mengusir penjajah, perjuanganmu akan lebih sulit karena melawan bangsamu sendiri." - Soekarno', type:'quote'},
        {content: '“Jangan mudah tergelincir dalam saat-saat seperti ini, segala tipu muslihat dan provokasi-provokasi yang tampak atau tersembunyi dapat dilalui dengan selamat, kalau kita waspada dan bertindak sebagai patriot.” - Jenderal Sudirman', type:'quote'},
        {content: '“Cita-cita persatuan Indonesia itu bukan omong kosong, tetapi benar-benar didukung oleh kekuatan-kekuatan yang timbul pada akar sejarah bangsa kita sendiri.” - Mohammad Yamin', type:'quote'},
      ],
    }),
    computed:{
      news(){
        return this.populateText(10)
      },
      category(){
        let items =[]
        for (let i = 0; i < 10 ; i++){
          items.push('category '+i)
        }
        return items
      },
      displayNews(){
        if (this.recentNews.length > 0){
          return this.recentNews.slice(0,this.displayItem)
        }
        return []
      },
      version_date() {
        var date = new Date()
        var year = date.getFullYear().toString()
        var month = date.getMonth() < 10 ? '0'+parseInt(date.getMonth()+1) : date.getMonth()
        return year.substr(2, 4) + '' + month + '' + date.getDate()
      },
      MbVersion() {
         return _g.getMbVersion()
      },
      MbBuild() {
         return _g.getMbBuild() + ' . ' + _g.getMbBuildCount()
      }
    },
    created(){
      // NewsService.fetchNews().then(response=>{
      //   let news = response.data.data
      //   let tempRecent = []
      //   for (let i = 0; i < news.length; i++){
      //     let article = news[i]
      //     article['link'] = article.isi.match(/(https?:\/\/[\w./\s-]+)/g)
      //     tempRecent.push(article)
      //   }
      //   // sorting dari id terbesar
      //   tempRecent.sort((a,b)=>{return b.id - a.id })
      //   this.recentNews = tempRecent
      // })
      var date = new Date()
      var year = date.getFullYear()
      var day = date.getDate()
      if(day <= 15){
        year = year-1
      }

      this.loadingBtn.dataBerita = true
      //var url_news = _g.getBaseURLAPIERK(this.currentYear, "v1/news/get")
      var url_news = "/api/news/get"
      axios.post(url_news, {tahun: this.currentYear}).then(response => {
        let news = response.data.data
        let tempRecent = []
        for (let i = 0; i < news.length; i++){
          let article = news[i]
          article['link'] = article.isi.match(/(https?:\/\/[\w./\s-]+)/g)
          tempRecent.push(article)
        }
        // sorting dari id terbesar
        tempRecent.sort((a,b)=>{return b.id - a.id })
        this.recentNews = tempRecent
      })
    },
    mounted() {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      document.head.appendChild(externalScript)
    },
    methods:{
      newTabLink(url){
        if(url){
          window.open(url,'_blank')
        }
      },
      displayCurrent(item){
        this.detailDialog=true
        this.currentDisplayed = item
      },
      updateNews(){
        this.displayItem += this.displayPerClick
      },
      populateText(limit){
        let items = []
        for (let i= 0; i < limit ; i++){
          let date = new Date()
          let text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
          items.push({
            date: this.generateRandomDate(date.setDate(date.getDate() - 5) , date, 0 , 23),
            pic: 'https:/picsum.photos/200/300',
            title: text.substr(0,10),
            text: text
          })
        }
        return items
      },
      generateRandomDate(start, end, startHour, endHour){
        var date = new Date(+start + Math.random() * (end - start));
        var hour = startHour + Math.random() * (endHour - startHour) | 0;
        date.setHours(hour);
        return date;
      },
      dummy(i){
        /* eslint-disable-next-line no-console */
        console.log('dummy '+i)
      },
      gotoLogin(){
        router.push('login')
      }
    }
  }
</script>
<style lang="css" scoped>
  .text-block{
    overflow:hidden;
    text-overflow: ellipsis;
    display:inline-block;
    white-space: nowrap;
  }
</style>
